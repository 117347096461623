<template>
  <div class="c-sign-up-send-email">
    <div class="c-sign-up-send-email__content">
      <div class="text-content">
        <p>已發送驗證連結至您的信箱，</p>
        <p>請點擊信內連結以完成註冊。</p>
      </div>

      <div class="btn-wrap">

      <mi-button @click="closeModal">確認</mi-button>
    </div>
    </div>

  </div>
</template>
<script>
import { defineComponent } from 'vue';
import MiButton from '@/components/Global/MiButton.vue';

export default defineComponent({
  name: 'SignUpSendEmail',
  components: {
    MiButton,
  },
  emits: ['update:show'],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit('update:show', false);
    };

    return {
      closeModal,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-sign-up-send-email {
  @include flex(center);
}

.text-content {
  margin-top: 64px;
  @include font-style($c-assist6, 16, medium, 0.8px, 24px);
}

.btn-wrap {
  margin-top: 45px;
  margin-bottom: 28px;
  text-align: center;
}
</style>
