<template>
  <div class="v-sign-in">
    <div class="v-sign-in__content">
      <div class="site-intro-bg">
        <div class="g-content">
          <div class="site-intro">
            <p class="site-intro__title">START EXPLORING MUSIC FOR FREE</p>

            <p class="site-intro__text">成為音韶試用會員，您可以：</p>

            <ul class="site-intro__list">
              <li class="site-intro__list__item">
                <span class="site-intro__list__item__text">線上完整預覽超過140萬首曲目</span>
              </li>
              <li class="site-intro__list__item">
                <span class="site-intro__list__item__text">建立喜愛歌單無上限並與他人分享</span>
              </li>
              <li class="site-intro__list__item">
                <span class="site-intro__list__item__text">使用影音結合體會功能搭配您的影片</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="sign-up">
        <div class="sign-up__content g-content">
          <div class="title-signin">
            <div class="title-signin__title">
              <red-decorate-title
                title="SIGN UP"
                size="large"
                :placement="{
                  bottom: '0px',
                  right: '-5px',
                }"
              />
            </div>
            <p class="title-signin__signin">
              Already a member?
              <router-link :to="{ name: 'sign-in' }">
                <span class="title-signin__signin__underline">Sign in</span>
              </router-link>
            </p>
          </div>

          <div class="signup-form-wrap">
            <n-form :label-width="80" :model="model" :rules="rules" :size="size" ref="formRef" label-placement="top">
              <n-grid :cols="12" :x-gap="20">
                <n-form-item-gi :span="gridColSetting.nationality" label="國籍" path="nationality">
                  <n-select placeholder="Select" :options="generalOptions" v-model:value="model.nationality" multiple />
                </n-form-item-gi>
                <n-form-item-gi :span="gridColSetting.city" label="居住城市/地區" path="city">
                  <n-select placeholder="Select" :options="generalOptions" v-model:value="model.nationality" multiple />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.name" label="姓名" path="name">
                  <n-input placeholder="Input" v-model:value="model.name" />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.gender" label="稱謂" path="gender">
                  <n-radio-group v-model:value="model.gender" name="radiogroup1">
                    <n-space>
                      <n-radio value="1">先生</n-radio>
                      <n-radio value="2">小姐</n-radio>
                    </n-space>
                  </n-radio-group>
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.phone" label="手機" path="phone">
                  <n-input placeholder="Input" v-model:value="model.phone" />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.telephone" label="電話" path="telephone">
                  <n-input placeholder="Input" v-model:value="model.telephone" />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.businessType" label="業務屬性" path="businessType">
                  <n-select
                    placeholder="Select"
                    :options="generalOptions"
                    v-model:value="model.businessType"
                    multiple
                  />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.companyName" label="公司名稱" path="companyName">
                  <n-input placeholder="Input" v-model:value="model.companyName" />
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.email" label="電子信箱" path="email">
                  <div class="input-remind-wrap">
                    <n-input placeholder="Input" v-model:value="model.email" />
                    <p class="remind-text">電子信箱即為您的登入帳號</p>
                  </div>
                </n-form-item-gi>

                <n-form-item-gi :span="gridColSetting.password" label="密碼" path="password">
                  <div class="input-remind-wrap">
                    <n-input placeholder="Input" v-model:value="model.password" />
                    <p class="remind-text">8-20 characters</p>
                  </div>
                </n-form-item-gi>
              </n-grid>
            </n-form>
          </div>

          <div class="sign-up-confirm">
            <div class="sign-up-confirm__btn-wrap">
              <mi-button @click="submit">Sign up</mi-button>
            </div>

            <p class="declare">
              By registering, you agree to the
              <router-link :to="{ name: 'licensing-terms' }"
                ><span class="declare__underline">Terms of Use</span></router-link
              >
              and <span class="declare__under-line">Privacy Policy</span>
            </p>
          </div>
        </div>
      </div>
    </div>

    <n-modal v-model:show="showModal" preset="dialog" :show-icon="false">
      <sign-up-send-email v-model:show="showModal" />
    </n-modal>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import {
  NForm, NInput, NFormItemGi, NGrid, NSelect, NSpace, NRadio, NModal,
} from 'naive-ui';

import MiButton from '@/components/Global/MiButton.vue';
import RedDecorateTitle from '@/components/Global/RedDecorateTitle.vue';
import SignUpSendEmail from '@/components/Global/Popup/SignUpSendEmail.vue';
import { useSiteStore } from '@/store/site';

export default defineComponent({
  name: 'SignUp',
  components: {
    MiButton,
    RedDecorateTitle,
    SignUpSendEmail,
    NModal,
    NForm,
    NInput,
    NGrid,
    NFormItemGi,
    NSelect,
    NSpace,
    NRadio,
  },
  setup() {
    // const message = useMessage();
    const showModal = ref(false);

    const siteStore = useSiteStore();

    const handleDialog = (status) => {
      showModal.value = status;
    };

    const submit = () => {
      handleDialog(true);
    };

    const gridColSetting = computed(() => {
      if (siteStore.deviceType.includes('portrait')) {
        return {
          nationality: 6,
          city: 6,
          name: 6,
          gender: 6,
          phone: 6,
          telephone: 6,
          businessType: 6,
          companyName: 6,
          email: 12,
          password: 12,
        };
      }

      return {
        nationality: 12,
        city: 12,
        name: 12,
        gender: 12,
        phone: 12,
        telephone: 12,
        businessType: 12,
        companyName: 12,
        email: 12,
        password: 12,
      };
    });

    return {
      gridColSetting,
      showModal,
      submit,
      size: ref('medium'),

      model: ref({
        nationality: '',
        city: '',
        name: '',
        gender: '',
        phone: '',
        telephone: '',
        businessType: '',
        companyName: '',
        email: '',
        password: '',
      }),

      rules: {
        nationality: {
          required: true,
          trigger: ['blur'],
        },
        city: {
          required: true,
          trigger: ['blur'],
        },
        gender: {
          required: true,
        },
        phone: {
          required: true,
          trigger: ['input'],
        },

        telephone: {
          trigger: ['input'],
        },
        businessType: {
          required: true,
          trigger: ['input'],
        },
        email: {
          required: true,
          trigger: ['input'],
        },
        password: {
          required: true,
          trigger: ['input'],
        },
      },
      generalOptions: ['groode', 'veli good', 'emazing', 'lidiculous'].map((v) => ({
        label: v,
        value: v,
      })),
    };
  },
});
</script>
<style lang="scss" scoped>
@import '~@/styles/share/form.scss';

.v-sign-in {
  height: 100%;

  &__content {
    @include flex(flex-start, flex-start, column);
    height: 100%;
  }
}

.site-intro-bg {
  flex: none;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url('~@/assets/profile/bg_signup.png');
  position: relative;
  height: 320px;
}

.site-intro {
  @include padding(80px 0px);
  @include flex(flex-start, flex-start, column);
  width: 340px;
  margin: 0;

  &__title {
    @include font-style($c-white, 26, normal, 0, 30px);
  }

  &__text {
    @include font-style($c-assist2, 16, normal, 0.8px, 24px);
    margin-top: 12px;
  }

  &__list {
    margin-top: 20px;

    &__item {
      position: relative;
      @include padding(0 0 0 20px);

      &::before {
        content: '';
        @include circle(4px);
        @include position(tl, 50%, 0);
        transform: translateY(-50%);
        display: inline-block;
        background-color: $c-main;
      }

      &__text {
        @include font-style($c-assist2, 16, normal, 0.8px, 24px);
      }
    }
  }
}

.title-signin {
  @include flex(space-between, flex-end);

  &__title {
  }

  &__signin {
    @include font-style($c-assist3, 14, 600, 0.28px, 24px);

    &__underline {
      text-decoration: underline;
      color: $c-assist3;
    }
  }
}

.signup-form-wrap {
  width: 100%;
  margin-top: 30px;

  // ::v-deep .n-form-item-label {
  //   @include font-style($c-assist3, 16, normal, 0.32px, 24px);
  // }
}

.sign-up {
  @include padding(36px 0 75px 0);
  flex: none;
  width: 100%;
  // overflow: auto;

  &__content {
    width: 100%;
    margin: 0 auto;
  }
}

.sign-up-confirm {
  margin-top: 50px;

  &__btn-wrap {
    text-align: center;
  }
}

.declare {
  text-align: center;
  margin-top: 20px;
  @include font-style($c-assist3, 14, normal, 0.28px, 24px);

  &__underline {
    @include font-style($c-assist3, 14, normal, 0.28px, 24px);
    text-decoration: underline;
  }
}
@media screen and (min-width: $portrait) {
}

@media screen and (min-width: $tablet) {
  .v-sign-in {
    &__content {
      @include flex();
    }
  }

  .site-intro-bg {
    flex: none;
    width: 50%;
    height: 100%;
  }
  .site-intro {
    @include position(tl, 20%, 50%);
    transform: translateX(-50%);
    padding: 0;
    width: 420px;

    &__title {
      @include font-style($c-white, 45, normal, 0, 60px);
    }

    &__text {
      @include font-style($c-assist2, 18, normal, 0.9px, 26px);
      margin-top: 60px;
    }

    &__list {
      margin-top: 16px;

      &__item {
        &__text {
          @include font-style($c-assist2, 18, normal, 0.9px, 30px);
        }
      }
    }
  }

  .signup-form-wrap {
    margin-top: 60px;
  }
  .sign-up {
    @include padding(155px 0 75px 0);
    width: 50%;
    height: 100%;
    overflow: auto;

    &__content {
      width: 440px;
    }
  }
}
</style>
